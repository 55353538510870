export const PROJECTS = [
  {
    id: '1',
    title: 'Ecomerce Shop',
    description:
      'Pixel Ecommerce Shop is a website that allows customers to purchase products and services through an online platform. ',
    image: {
      src: '',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/hunggoodkidz/ecommerce-shop',
    },
    technologies: [
      '#C#',
      '#.NET Framework',
      '#ASP.NET Framework',
      '#Model View Controller',
      '#MSSQL Server',
    ],
  },
  {
    id: '2',
    title: 'AI Image Generator',
    description:
      'Raw images are generated from user-supplied text descriptions using the OpenAI API and the DALL-E model. The project also has a front- end interface built with React, allowing users to easily create their own images. ',
    image: {
      src: '',
      placeholderSrc:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
    },
    links: {
      site: 'https://github.com/hunggoodkidz/ai-image-generator',
    },
    technologies: [
      '#Javascript',
      '#React.js',
      '#Express.js',
      '#MongoDB',
      '#Node.js',
      '#Cloundinary',
      '#Vite.js',
    ],
  },
  {
      id: '3',
      title: 'Mobile Shop Management System',
      description:
        'A software application management designed to streamline the operations of a mobile shop. This project aims to automate the processes involved in managing a store which is selling smartphone including inventory management, sales management, customer management, and employee managemen ',
      image: {
        src: '',
        placeholderSrc:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
      },
      links: {
        site: 'https://github.com/hunggoodkidz/mobile-shop-management',
      },
      technologies: [
        '#Java',
        '#MySQL',
        '#XAMPP',
      ],
    },
    {
      id: '4',
      title: 'Pixel Shop App',
      description:
        'An android app mobile run ecommerce shop platform to sell technology products  and easily used by User to purchase online and add payment. Including admin system management. ',
      image: {
        src: '',
        placeholderSrc:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
      },
      links: {
        site: 'https://github.com/hunggoodkidz/pixelshop-app',
      },
      technologies: [
        '#Java',
        '#Android Studio',
        '#Firebase',
        '#Momo Payment API',
      ],
    },
    {
      id: '5',
      title: 'HotPost Social Media',
      description:
        'A Social media website that allows people to connect, communicate and share information with others online. With the explosion of the internet and the growth of social networks, the world has become more interconnected than ever before. ',
      image: {
        src: '',
        placeholderSrc:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
      },
      links: {
        site: 'https://github.com/hunggoodkidz/HotPost',
      },
      technologies: [
        '#MongoDB',
        '#React JS',
        '#Express JS',
        '#Node JS',
        '#JavaScript',
        '#Redux',
      ],
    },
    {
      id: '6',
      title: 'GraphQL CRUD Coffee API',
      description:
        'This is a demo project that will introduce you to Spring for GraphQL. The Spring Boot application uses Spring for GraphQL and creates a single endpoint at GraphQL queries. ',
      image: {
        src: '',
        placeholderSrc:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzIDIiPjwvc3ZnPg==',
      },
      links: {
        site: 'https://github.com/hunggoodkidz/graphql-coffee-api-crud-demo',
      },
      technologies: [
        '#Java',
        '#Java Spring Boot',
        '#GraphQL',
        '#Java Spring For GraphQL',
        '#React JS',
        '#Node JS',
        '#JavaScript',
      ],
    },



];
